<template lang="">
  <div>
    <!-- SECTION Filter -->
    <BCard
      header-class="py-1"
      body-class="pb-1"
    >
      <template #header>
        <div class="font-weight-bolder text-uppercase">
          {{ $t('filter') }}
        </div>
      </template>
      <BRow>
        <!-- ANCHOR startDate -->
        <BCol
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            class="w-100 mb-0"
            :label="$t('reservation.lowFareTracking.startDate')"
            label-for="range-date"
          >
            <b-input-group
              id="range-date"
              class=""
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr
                v-model="startDate"
                class="form-control form-control-md"
                :config="{
                  dateFormat: 'Y-m-d',
                  altFormat: 'd-m-Y',
                  class: 'form-control-md',
                  altInput: true,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
                :placeholder="$t('reservation.lowFareTracking.startDate')"
              />

            </b-input-group>
          </b-form-group>
        </BCol>

        <!-- ANCHOR endDate -->
        <BCol
          md="6"
          cols="12"
          lg="3"
          class="mt-50 mt-md-0"
        >
          <b-form-group
            class="w-100 mb-0"
            :label="$t('reservation.lowFareTracking.endDate')"
            label-for="range-date"
          >
            <b-input-group
              id="range-date"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr
                v-model="endDate"
                class="form-control form-control-md"
                :config="{
                  dateFormat: 'Y-m-d',
                  altFormat: 'd-m-Y',
                  class: 'form-control-md',
                  altInput: true,
                  minDate: startDate,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
                :placeholder="$t('reservation.lowFareTracking.endDate')"
              />

            </b-input-group>
          </b-form-group>
        </BCol>

        <!-- ANCHOR trackingFrom -->
        <BCol
          md="6"
          cols="12"
          lg="3"
          class="mt-50 mt-lg-0"
        >
          <b-form-group
            :label="$t('reservation.lowFareTracking.lowFareTrackingStatus')"
            label-for="is-tracking-from"
            class="w-100 mb-0"
          >
            <v-select
              id="is-tracking-from"
              v-model="status"
              class="w-100"
              style="font-size: 1rem;"
              :options="taskStatusOptions"
              label="label"
              clearable
              :placeholder="$t('reservation.lowFareTracking.tracking')"
              :reduce="val => val.value"
            >
              <template #option="data">
                <span>
                  {{ $t(`reservation.lowFareTracking.${data.label}`) }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ $t(`reservation.lowFareTracking.${data.label}`) }}
                </span>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </BCol>

        <!-- ANCHOR - BUTTONs -->
        <BCol
          cols="12"
          md="6"
          lg="3"
          class="mt-50 mt-lg-0 d-flex-center justify-content-md-end gap-2"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="font-weight-bolder p-75 text-nowrap"
            variant="outline-warning"
            :disabled="isDisabledButtons"
            @click="submitSearch"
          >
            <feather-icon
              v-if="!isMobileView"
              icon="SearchIcon"
              class="text-warning"
            />
            <span
              class="align-middle text-warning font-weight-bolder"
            >
              {{ $t('search') }}
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="font-weight-bolder p-75 text-nowrap"
            variant="outline-danger"
            :disabled="isDisabledButtons"
            @click="clearFilter"
          >
            <feather-icon
              v-if="!isMobileView"
              icon="RefreshCwIcon"
              class="text-danger"
            />
            <span
              class="align-middle text-danger font-weight-bolder"
            >
              {{ $t('reservation.lowFareTracking.delete') }}
            </span>
          </b-button>
        </BCol>
      </BRow>
    </BCard>
    <!-- !SECTION -->

    <!-- SECTION Card ket qua -->
    <BCard header-class="py-1">
      <template #header>
        <div class="font-weight-bolder text-uppercase">
          {{ $t('reservation.lowFareTracking.title') }}
        </div>
      </template>

      <BTable
        style="max-height: calc(100vh - 400px); height: calc(100vh - 400px)"
        :sticky-header="true"
        class="position-relative"
        :items="resultArray"
        responsive
        :striped="true"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('noMatchingResult')"
        no-border-collapse
        small
        :busy="loading"
        :hover="true"
        :borderless="true"
      >
        <template #table-busy>
          <div class="d-flex-center text-dark my-2 gap-2">
            <b-spinner class="align-middle" />
            <strong>{{ $t('loading') }}</strong>
          </div>
        </template>

        <template
          v-for="column in tableColumns"
          v-slot:[`head(${column})`]=""
        >
          <span
            :key="column"
            class="text-dark text-nowrap"
          >
            {{ $t(`reservation.lowFareTracking.${column}`) }}
          </span>
        </template>

        <template #cell(source)="data">
          <div class="text-nowrap d-flex-center">
            <span
              class="h5 mb-0 py-25 px-50 d-flex-center gap-1"
              :style="resolveBorderColorByAirline(data.item.source, 900)"
            >
              {{ data.item.source }}
              <span
                v-if="data.item.extra && data.item.extra.length && data.item.extra[0].contentSource"
                :class="`bagde-${data.item.extra[0].contentSource}`"
              >
                {{ data.item.extra[0].contentSource }}
              </span>
            </span>
          </div>
        </template>

        <template #cell(passengers)="data">
          <div
            v-for="(pax, index) of data.item.paxLists"
            :key="index"
          >
            <small class="text-body-3 text-nowrap">
              {{ pax.lastName }} {{ pax.firstName }} {{ pax.title }}
            </small>
          </div>
        </template>

        <template #cell(flight)="data">
          <div
            v-if="data.item.bookingRequest"
          >
            <div
              v-for="(trip, index) of resolveBookingRequest(data.item.bookingRequest.itineraries)"
              :key="index"
              class="text-nowrap text-body-3"
            >
              <div
                v-for="(segment,indexSegment) in trip.segments"
                :key="indexSegment"
                class="d-flex flex-nowrap my-50"
              >
                <IAmLogoAirline
                  v-if="segment.airline"
                  :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                  size="xs"
                  rounded
                  custom-class="mr-50"
                />
                <span>
                  {{ segment.devSegmentIndex }}
                  {{ `${segment.airline}${segment.flightNumber}` }}
                  {{ segment.bookingClass }}
                  {{ convertISODateTime(segment.departureTime).dayMonth }}
                  {{ `${segment.departure}${segment.arrival}` }}
                  {{ convertISODateTime(segment.departureTime).hourMin }}
                  {{ convertISODateTime(segment.arrivalTime).hourMin }}
                </span>
              </div>
            </div>
          </div>

          <div
            v-else
          >
            <div
              v-for="(trip, index) of data.item.itineraries.filter(trip => trip.some(segment => segment.status !== 'HX'))"
              :key="index"
              class="text-nowrap text-body-3"
            >
              <div
                v-for="(segment,indexSegment) in trip"
                :key="indexSegment"
                class="d-flex flex-nowrap my-50"
              >
                <IAmLogoAirline
                  v-if="segment.airline"
                  :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                  size="xs"
                  rounded
                  custom-class="mr-50"
                />
                <span>
                  {{ convertShortTrip(segment) }}
                </span>
              </div>
            </div>
          </div>
        </template>

        <template #cell(createdAt)="data">
          <b-media no-body>
            <b-media-body>
              <h6 class="mb-0">
                {{ convertISODateTime(data.item.dateCreate).time }}
              </h6>
              <h6 class="mb-0">
                {{ convertISODateTime(data.item.dateCreate).date }}
              </h6>
            </b-media-body>
          </b-media>
        </template>

        <template #cell(bookingCode)="{ item }">
          <b-link
            :to="{ name: 'apps-reservations-modify', params: { id: item.id } }"
            class="font-weight-bold d-block text-nowrap text-info pb-0"
          >
            {{ item.bookingCode }}
          </b-link>
        </template>

        <template #cell(lowFareTrackingCode)="{ item }">
          <b-button
            v-for="(booking, index) in handleLowFareTracking(item).flat()"
            :key="index"
            variant="outline-info"
            class="text-nowrap py-25 px-1 font-medium-1 rounded"
            @click="openLinkedBookingHandle(booking.id)"
          >
            {{ booking.pnr }}
          </b-button>
        </template>

        <template #cell(lowFareTrackingStatus)="{ item }">
          <b-badge
            v-if="item.lowFareTrackingStatus"
            :variant="`light-${handleStatusColor(item.lowFareTrackingStatus)}`"
            :class="`badge-glow round text-uppercase`"
          >
            {{ $t(`reservation.lowFareTracking.${item.lowFareTrackingStatus.toLowerCase()}`) }}
          </b-badge>
        </template>

        <template #cell(booker)="data">
          <div
            :id="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
            class="w-100 h-100"
          >
            <small class="text-info font-weight-bold text-body-3 text-nowrap">
              {{ data.item.createdBy.username }}
            </small>
            <b-tooltip
              :target="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
              triggers="hover focus"
              placement="top"
              boundary="viewport"
              variant="info"
            >
              <div>
                {{ data.item.createdBy.lastName }}
                {{ data.item.createdBy.firstName }}
                ({{ data.item.createdBy.type }})
              </div>
              <div>
                {{ data.item.createdBy.employeeCode }}
              </div>
            </b-tooltip>
          </div>
        </template>
      </BTable>

      <!-- ANCHOR: Table Footer -->
      <b-row class=" d-flex-center justify-content-md-between">
        <b-col
          cols="12"
          md="auto"
          class="d-flex align-items-center justify-content-center mb-50 mb-md-0"
        >
          <span class="font-weight-bolder">
            {{ $t('paginationText.showing') }}
          </span>
          <v-select
            v-model="sizePerPage"
            :options="sizePerPageLgOptions"
            :clearable="false"
            :append-to-body="true"
            :calculate-position="withPopper"
            class="per-page-selector d-inline-block mx-50"
          >
            <template #option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <template #selected-option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
          <span class="font-weight-bolder">
            {{ $t('paginationText.from') }} {{ fromItem }} {{ $t('paginationText.to') }} {{ toItem }} {{ $t('paginationText.outOf') }} {{ totalItems }}
          </span>
        </b-col>

        <b-col
          cols="12"
          md="auto"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="sizePerPage"
            first-number
            last-number
            class="mb-0 mt-25 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>

    </BCard>
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BInputGroup, BInputGroupPrepend, BFormGroup, BTable, BSpinner, BPagination,
  BMedia, BMediaBody, BLink, BBadge, BTooltip,
} from 'bootstrap-vue'
import {
  ref, computed, watch, nextTick,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import moment from 'moment'
import { createPopper } from '@popperjs/core'

import router from '@/router'
import { apiReservation } from '@/api'
import {
  taskStatusOptions,
  sizePerPageLgOptions,
  resolveBookingStatusVariant,
} from '@/constants/selectOptions'

import {
  resolveBorderColorByAirline,
  convertISODateTime,
} from '@core/utils/filter'
import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BMedia,
    BMediaBody,
    BTooltip,
    BFormGroup,
    BLink,
    BBadge,
    BTable,
    BSpinner,
    BPagination,
    vSelect: () => import('vue-select'),
    flatPickr: () => import('vue-flatpickr-component'),
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownMaxWidth = width || '200px'
      dropdownList.style.maxWidth = dropdownMaxWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
  setup() {
    const { toastError } = useToast()
    const DAY_DIFF_DEFAULT = 7

    // ANCHOR - DEFINE FILTERS
    const startDate = ref(moment().subtract(DAY_DIFF_DEFAULT, 'days').format('YYYY-MM-DD'))
    const endDate = ref(moment().format('YYYY-MM-DD'))
    const status = ref(null)
    const resultArray = ref([])
    const currentPage = ref(1)
    const sizePerPage = ref(20)
    const loading = ref(false)
    const totalItems = ref(0)

    function submitSearch() {
      loading.value = true
      const params = {
        startDate: startDate.value || undefined,
        endDate: endDate.value || undefined,
        status: status.value !== null ? status.value : undefined,
        page: currentPage.value || 1,
        size: sizePerPage.value || 20,
      }
      apiReservation.lowFareTrackingStatistic(params)
        .then(res => {
          resultArray.value = res.data.items
          totalItems.value = res.data.total
        })
        .catch(() => {
          toastError('Lỗi tìm kiếm')
        })
        .finally(() => {
          loading.value = false
        })
    }

    function resolveBookingRequest(trips) {
      let indexSegment = 1
      return trips.map(trip => ({
        ...trip,
        segments: trip.segments.map(segment => {
          const data = {
            ...segment,
            devSegmentIndex: String(indexSegment),
          }

          indexSegment += 1
          return data
        }),
      }))
    }

    const handleStatusColor = status => {
      if (status === 'SUCCESS') {
        return 'success'
      } if (status === 'PROCESSING') {
        return 'warning'
      } if (status === 'CANCELLED') {
        return 'danger'
      }
      return 'light'
    }

    const handleLowFareTracking = item => item.lowFareTrackingTasks.filter(vr => vr.result).map(task => JSON.parse(task.result).lowFareTrackedBookings.map(ve => ({ ...ve })))

    const fromItem = computed(() => sizePerPage.value * (currentPage.value - 1) + 1)
    const toItem = computed(() => (sizePerPage.value * (currentPage.value)) >= totalItems.value ? totalItems.value : (sizePerPage.value * (currentPage.value)))

    watch([currentPage, sizePerPage], () => {
      submitSearch()
    })

    submitSearch()

    function clearFilter() {
      startDate.value = moment().subtract(DAY_DIFF_DEFAULT, 'days').format('YYYY-MM-DD')
      endDate.value = moment().format('YYYY-MM-DD')
      status.value = null
      nextTick(() => { submitSearch() })
    }

    const isDisabledButtons = computed(() => moment(endDate.value) < moment(startDate.value))

    watch(() => startDate.value, () => {
      if (startDate.value && (endDate.value < startDate.value)) {
        endDate.value = moment(startDate.value).add(DAY_DIFF_DEFAULT, 'days').format('YYYY-MM-DD')
      }
    })
    // ANCHOR Result
    const tableColumns = ref(['source', 'bookingCode', 'lowFareTrackingStatus', 'lowFareTrackingCode', 'passengers', 'flight', 'booker', 'createdAt'])

    function openLinkedBookingHandle(id) {
      router
        .push({ name: 'apps-reservations-modify', params: { id } })
        .then(() => {
          this.$bvModal.hide('modal-modify-low-fare-tracking')
        })
        .catch(() => { })
    }

    return {
      // variable filter
      startDate,
      endDate,
      status,
      taskStatusOptions,
      resolveBookingStatusVariant,
      openLinkedBookingHandle,

      loading,
      isDisabledButtons,

      // result
      resultArray,
      tableColumns,
      resolveBookingRequest,

      // methods
      submitSearch,
      clearFilter,

      // extras
      Vietnamese,
      removeAccentsUpperCaseFormatter,
      convertISODateTime,
      sizePerPageLgOptions,
      currentPage,
      sizePerPage,
      totalItems,
      fromItem,
      toItem,
      handleStatusColor,

      resolveBorderColorByAirline,
      handleLowFareTracking,
    }
  },
}
</script>

<style lang="">

</style>
