var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BCard', {
    attrs: {
      "header-class": "py-1",
      "body-class": "pb-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "font-weight-bolder text-uppercase"
        }, [_vm._v(" " + _vm._s(_vm.$t('filter')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('BRow', [_c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0",
    attrs: {
      "label": _vm.$t('reservation.lowFareTracking.startDate'),
      "label-for": "range-date"
    }
  }, [_c('b-input-group', {
    attrs: {
      "id": "range-date"
    }
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  })], 1), _c('flat-pickr', {
    staticClass: "form-control form-control-md",
    attrs: {
      "config": {
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        class: 'form-control-md',
        altInput: true,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      },
      "placeholder": _vm.$t('reservation.lowFareTracking.startDate')
    },
    model: {
      value: _vm.startDate,
      callback: function callback($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1)], 1)], 1), _c('BCol', {
    staticClass: "mt-50 mt-md-0",
    attrs: {
      "md": "6",
      "cols": "12",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0",
    attrs: {
      "label": _vm.$t('reservation.lowFareTracking.endDate'),
      "label-for": "range-date"
    }
  }, [_c('b-input-group', {
    attrs: {
      "id": "range-date"
    }
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  })], 1), _c('flat-pickr', {
    staticClass: "form-control form-control-md",
    attrs: {
      "config": {
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        class: 'form-control-md',
        altInput: true,
        minDate: _vm.startDate,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      },
      "placeholder": _vm.$t('reservation.lowFareTracking.endDate')
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1)], 1)], 1), _c('BCol', {
    staticClass: "mt-50 mt-lg-0",
    attrs: {
      "md": "6",
      "cols": "12",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0",
    attrs: {
      "label": _vm.$t('reservation.lowFareTracking.lowFareTrackingStatus'),
      "label-for": "is-tracking-from"
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "id": "is-tracking-from",
      "options": _vm.taskStatusOptions,
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('reservation.lowFareTracking.tracking'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("reservation.lowFareTracking.".concat(data.label))) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("reservation.lowFareTracking.".concat(data.label))) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  })], 1)], 1), _c('BCol', {
    staticClass: "mt-50 mt-lg-0 d-flex-center justify-content-md-end gap-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "font-weight-bolder p-75 text-nowrap",
    attrs: {
      "variant": "outline-warning",
      "disabled": _vm.isDisabledButtons
    },
    on: {
      "click": _vm.submitSearch
    }
  }, [!_vm.isMobileView ? _c('feather-icon', {
    staticClass: "text-warning",
    attrs: {
      "icon": "SearchIcon"
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle text-warning font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('search')) + " ")])], 1), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "font-weight-bolder p-75 text-nowrap",
    attrs: {
      "variant": "outline-danger",
      "disabled": _vm.isDisabledButtons
    },
    on: {
      "click": _vm.clearFilter
    }
  }, [!_vm.isMobileView ? _c('feather-icon', {
    staticClass: "text-danger",
    attrs: {
      "icon": "RefreshCwIcon"
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle text-danger font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFareTracking.delete')) + " ")])], 1)], 1)], 1)], 1), _c('BCard', {
    attrs: {
      "header-class": "py-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "font-weight-bolder text-uppercase"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFareTracking.title')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('BTable', {
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "calc(100vh - 400px)",
      "height": "calc(100vh - 400px)"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.resultArray,
      "responsive": "",
      "striped": true,
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "busy": _vm.loading,
      "hover": true,
      "borderless": true
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, _vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn() {
          return [_c('span', {
            key: column,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.lowFareTracking.".concat(column))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(source)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap d-flex-center"
        }, [_c('span', {
          staticClass: "h5 mb-0 py-25 px-50 d-flex-center gap-1",
          style: _vm.resolveBorderColorByAirline(data.item.source, 900)
        }, [_vm._v(" " + _vm._s(data.item.source) + " "), data.item.extra && data.item.extra.length && data.item.extra[0].contentSource ? _c('span', {
          class: "bagde-".concat(data.item.extra[0].contentSource)
        }, [_vm._v(" " + _vm._s(data.item.extra[0].contentSource) + " ")]) : _vm._e()])])];
      }
    }, {
      key: "cell(passengers)",
      fn: function fn(data) {
        return _vm._l(data.item.paxLists, function (pax, index) {
          return _c('div', {
            key: index
          }, [_c('small', {
            staticClass: "text-body-3 text-nowrap"
          }, [_vm._v(" " + _vm._s(pax.lastName) + " " + _vm._s(pax.firstName) + " " + _vm._s(pax.title) + " ")])]);
        });
      }
    }, {
      key: "cell(flight)",
      fn: function fn(data) {
        return [data.item.bookingRequest ? _c('div', _vm._l(_vm.resolveBookingRequest(data.item.bookingRequest.itineraries), function (trip, index) {
          return _c('div', {
            key: index,
            staticClass: "text-nowrap text-body-3"
          }, _vm._l(trip.segments, function (segment, indexSegment) {
            return _c('div', {
              key: indexSegment,
              staticClass: "d-flex flex-nowrap my-50"
            }, [segment.airline ? _c('IAmLogoAirline', {
              attrs: {
                "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
                "size": "xs",
                "rounded": "",
                "custom-class": "mr-50"
              }
            }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(segment.devSegmentIndex) + " " + _vm._s("".concat(segment.airline).concat(segment.flightNumber)) + " " + _vm._s(segment.bookingClass) + " " + _vm._s(_vm.convertISODateTime(segment.departureTime).dayMonth) + " " + _vm._s("".concat(segment.departure).concat(segment.arrival)) + " " + _vm._s(_vm.convertISODateTime(segment.departureTime).hourMin) + " " + _vm._s(_vm.convertISODateTime(segment.arrivalTime).hourMin) + " ")])], 1);
          }), 0);
        }), 0) : _c('div', _vm._l(data.item.itineraries.filter(function (trip) {
          return trip.some(function (segment) {
            return segment.status !== 'HX';
          });
        }), function (trip, index) {
          return _c('div', {
            key: index,
            staticClass: "text-nowrap text-body-3"
          }, _vm._l(trip, function (segment, indexSegment) {
            return _c('div', {
              key: indexSegment,
              staticClass: "d-flex flex-nowrap my-50"
            }, [segment.airline ? _c('IAmLogoAirline', {
              attrs: {
                "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
                "size": "xs",
                "rounded": "",
                "custom-class": "mr-50"
              }
            }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(_vm.convertShortTrip(segment)) + " ")])], 1);
          }), 0);
        }), 0)];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-body', [_c('h6', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(data.item.dateCreate).time) + " ")]), _c('h6', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(data.item.dateCreate).date) + " ")])])], 1)];
      }
    }, {
      key: "cell(bookingCode)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info pb-0",
          attrs: {
            "to": {
              name: 'apps-reservations-modify',
              params: {
                id: item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.bookingCode) + " ")])];
      }
    }, {
      key: "cell(lowFareTrackingCode)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return _vm._l(_vm.handleLowFareTracking(item).flat(), function (booking, index) {
          return _c('b-button', {
            key: index,
            staticClass: "text-nowrap py-25 px-1 font-medium-1 rounded",
            attrs: {
              "variant": "outline-info"
            },
            on: {
              "click": function click($event) {
                return _vm.openLinkedBookingHandle(booking.id);
              }
            }
          }, [_vm._v(" " + _vm._s(booking.pnr) + " ")]);
        });
      }
    }, {
      key: "cell(lowFareTrackingStatus)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.lowFareTrackingStatus ? _c('b-badge', {
          class: "badge-glow round text-uppercase",
          attrs: {
            "variant": "light-".concat(_vm.handleStatusColor(item.lowFareTrackingStatus))
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("reservation.lowFareTracking.".concat(item.lowFareTrackingStatus.toLowerCase()))) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(booker)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 h-100",
          attrs: {
            "id": "booker-".concat(data.item.createdBy.id, "-").concat(data.item.createdAt)
          }
        }, [_c('small', {
          staticClass: "text-info font-weight-bold text-body-3 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.createdBy.username) + " ")]), _c('b-tooltip', {
          attrs: {
            "target": "booker-".concat(data.item.createdBy.id, "-").concat(data.item.createdAt),
            "triggers": "hover focus",
            "placement": "top",
            "boundary": "viewport",
            "variant": "info"
          }
        }, [_c('div', [_vm._v(" " + _vm._s(data.item.createdBy.lastName) + " " + _vm._s(data.item.createdBy.firstName) + " (" + _vm._s(data.item.createdBy.type) + ") ")]), _c('div', [_vm._v(" " + _vm._s(data.item.createdBy.employeeCode) + " ")])])], 1)];
      }
    }], null, true)
  }), _c('b-row', {
    staticClass: " d-flex-center justify-content-md-between"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center mb-50 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.showing')) + " ")]), _c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "options": _vm.sizePerPageLgOptions,
      "clearable": false,
      "append-to-body": true,
      "calculate-position": _vm.withPopper
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  }), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.from')) + " " + _vm._s(_vm.fromItem) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.toItem) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.totalItems) + " ")])], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-25 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalItems,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }